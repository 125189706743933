<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search meeting"
            type="text"
          />
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'title'">
            {{ props.row.title }} <br> <small class="text-capitalize"><b-badge variant="light-primary">{{
              props.row.type
            }}</b-badge></small>

          </span>
          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'buttons'"
            class="d-flex"
          >
            <b-button
              class="mr-50"
              size="sm"
              variant="outline-primary"
              :to="{
                name: 'competitions-application-manager',
                params: { id: props.row.id },
              }"
            >
              Manage Competition
            </b-button>
            <!-- <b-button
              v-b-tooltip="'Add Participants'"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('addParticipants')"
            >
              <feather-icon
                icon="UserPlusIcon"
              />
            </b-button> -->
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    // flatPickr,
  },
  data() {
    return {
      title: '',
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      message: '',
      operationsId: null,
      operations: [
        {
          id: '1',
          label: 'Milestones',
          children: [],
        },
        {
          id: '2',
          label: 'Requests',
          children: [],
        },
        {
          id: '3',
          label: 'Mentoring',
          children: [],
        },
        {
          id: '4',
          label: 'Partner Services',
          children: [],
        },
        {
          id: '5',
          label: 'General',
          children: [{
            id: '',
            label: 'General',
          }],
        },
      ],
      status: '',
      mutationLoading: false,
      meetingTypeOptions: [],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Competition Status',
          field: 'status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Startup Status',
          field(row) {
            return row.programs_applicantstables[0]?.programs_startupparticipants?.length ? 'Participant' : 'Applicant'
          },
          tdClass: 'text-capitalize',
        },
        {
          label: 'Action',
          field: 'buttons',
          sortable: false,
        },

      ],
      rows: [],
      selectedOperation: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  methods: {
    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_programs_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        // Association through users_organizationtable: Checks association of incubator
        // Association through programs_applicantstables: Checks association of startup
        return gql`
        query startup_competitions {
          programs_basicinfo(where: {
          is_competition: {_eq: true},
          users_organizationtable: {usersAssociationtablesByAssociatedOrg: {id: {_eq: ${this.$route.params.id} }}},
          programs_applicantstables: {users_organizationtable: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}}}) {
           id
           title
           status
           programs_applicantstables(where: {users_organizationtable: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}}){
            programs_startupparticipants {
              id
            }
           }
          }
      }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
